import React from 'react'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
export default function ImageUploader({ onChange, file, aspect }) {

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    return (
        <ImgCrop rotationSlider aspect={aspect}>
            <Upload
                listType="picture-card"
                className="avatar-uploader"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                showUploadList={false}
                onChange={onChange}
                onPreview={onPreview}
            >
                
                {file ? (
                    <img
                    src={typeof file === 'object' ? URL.createObjectURL(file) : file}
                        alt="avatar"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                        }}
                    />
                    ) : (
                        <span>+ Upload</span>
                        )}
            </Upload>
        </ImgCrop>
    )
}
