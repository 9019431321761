import React, { useState } from "react";
import Cropper from "react-easy-crop";
import axios from 'axios';
export default function VideoCroper() {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [video, setVideo] = useState("");
  const [croppedvideo, setCroppedVideo] = useState("");
  const [cropSize, setCropSize] = useState("");
  const handleVideo=async()=>{
    const formData = new FormData();
    formData.append('video', video);
    formData.append('crop', JSON.stringify(crop));
    formData.append('cropSize', JSON.stringify(cropSize));
    formData.append('zoom', zoom);
    const result=await axios.post('http://localhost:8000/cropper',formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((res)=>{
      console.log("the response is ",res.data);
    }).catch((err)=>{
      console.log("error: ",err);
    })
    return result;
  }
  const handleVideoSize = (e)=>{
    setCroppedVideo(URL.createObjectURL(e.target.files[0]))
    setVideo(e.target.files[0])
  }
  return (
    <div>
      <input
        type="file"
        onChange={handleVideoSize}
      />
      <button onClick={()=>handleVideo()}>Crop Video</button>
      {/* <div className="crop-container"> */}
       <div className="container h-screen position-relative">
       <Cropper
          video={croppedvideo}
          crop={crop}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropSizeChange={setCropSize}
          onImageLoaded={(res) => {
            console.log(res);
          }}
        />
       </div>
      {/* </div> */}
      <div className="controls" />
    </div>
  );
}
