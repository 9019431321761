import React, { useContext } from "react";

/// React router dom
import { Route, Routes } from "react-router-dom";

/// Css
import "./chart.css";
import "./index.css";
import "./step.css";

/// Layout
import { ThemeContext } from "../context/ThemeContext";
import Footer from "./layouts/Footer";
import Setting from "./layouts/Setting";
import Nav from "./layouts/nav";
import SavedTribute from "./pages/SavedTribute";
import Tribute from "./pages/Tribute";
import VideoCroper from "./pages/videoCroper";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    { url: "/SavedTribute", component: SavedTribute },
    { url: "/", component: Tribute }, 
    { url: "/video", component: VideoCroper }, 
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div className={`${!pagePath ? "container-fluid" : ""}`}>
            <Routes>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  element={<data.component />}
                />
              ))}
            </Routes>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
