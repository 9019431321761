import axios from "axios";
import { toast } from "react-toastify";
import { loginConfirmedAction } from "../store/actions/AuthActions";
import { BASE_URL_API } from "../utils/variables";
import { axiosInstance } from "./AxiosInstance";

export function signUp(name, email, password, code_id) {
  //axios call
  const postData = {
    name,
    email,
    password,
    code_id,
  };
  return axiosInstance.post(
    `user/register`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(`${BASE_URL_API}user/login`, postData);
}

export function formatError(errorResponse) {
  switch (errorResponse) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      toast.error("Oops", "Email already exists", "error");
      break;
    case "The selected email is invalid.":
      //return 'Email not found';
      toast.error("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "Invalid Credentials":
      //return 'Invalid Password';
      toast.error("Oops", "Invalid Password", "error", {
        button: "Try Again!",
      });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("userDetails", tokenDetails);
}

// export function runLogoutTimer(dispatch, timer, history) {
//   setTimeout(() => {
//     dispatch(logout(history));
//   }, timer);
// }

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  // let tokenDetails = "";
  if (!tokenDetailsString) {
    // dispatch(logout(history));
    return;
  }

  // let expireDate = new Date(tokenDetails.expireDate);
  // let todaysDate = new Date();

  // if (todaysDate > expireDate) {
  //   dispatch(logout(history));
  //   return;
  // }
  dispatch(loginConfirmedAction(tokenDetailsString));

  // const timer = expireDate.getTime() - todaysDate.getTime();
  // runLogoutTimer(dispatch, timer, history);
}
