import React, { useState, useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { axiosInstance } from "../../services/AxiosInstance";
import ImageUploader from "../components/ImgCropUploader";
import { GithubPicker } from "react-color";
import Background from "../components/Background";
import { FaPlus } from "react-icons/fa";

const Tribute = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [values, setValues] = useState(null);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [binaryimg, setBinaryimg] = useState();
  const [more, setMore] = useState([]);
  // const [video, ] = useState("");
  const [asEditable, setAsEditable] = useState(false);
  const [quoteText, setQuoteText] = useState("In Loving Memory");
  const [color, setColor] = useState("#fff");
  const [colorPicker, setColorPicker] = useState(false);
  const [TextSize, setTextSize] = useState("5rem");
  // const { fontSize } = { fontSize: "5rem" };
  const [showTextEdit, setShowEdit] = useState('20px');
  const [Showinputseditstext, setShowinputseditstext] = useState(false);

  const getColorCode = (e) => {
    setColor(e.hex);
    console.log(e.hex);
  };
  useEffect(() => {
    handleShow();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const AddMore = () => {
    const newMore = Array.from({ length: 1 }, (_, index) => index + 1);
    setMore([...more, ...newMore]);
  };

  const handleChangeText = (e) => {
    e.preventDefault();
    // setQuoteText(e.target.value);
    setAsEditable(false);
  };

  const FileUpload = (e, name) => {
    // setPopsinger({...popsinger,index:e.target.files[0]  })
    const value = e.file.originFileObj;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const AddQr = () => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("heading", quoteText);
    formdata.append("color", color);
    let gal = data.reduce((accumulator, currentValue) => {
      if (currentValue !== undefined) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);

    gal.map((e, i) => {
      formdata.append(`gallery[${i}]`, e.file);
      formdata.append(`gallery_name[${i}]`, e.value);
      return true;
    });
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        const element = values[key];
        formdata.append(key, element);
      }
    }
    // for (const key in binaryimg) {
    //   if (Object.hasOwnProperty.call(binaryimg, key)) {
    //     const element = binaryimg[key];
    //     formdata.append(key, element);
    //   }
    // }
    axiosInstance
      .post(`user/tribute/store`, formdata)
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message);
          navigate("/SavedTribute");
          console.log(response);
        } else {
          toast.error(response.data.message);
          console.log(response);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleChange2 = (event, index) => {
    const value = event?.target?.value;
    const files = event?.file?.originFileObj;
    if (files) {
      const file = files;
      setData((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], file };
        return newData;
      });
    } else {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], value };
        return newData;
      });
    }
  };

  return (
    <>
      {loader && <div className="loader" />}
      {/* <input
        type="file"
        name=""
        id=""
        onChange={(e) => setVideo(e.target.files[0])}
      /> */}
      <Container>
        <form id="profile form"></form>
        <div className="d-flex justify-content-end align-items-end mb-2">
          {colorPicker ? (
            <GithubPicker color={color} onChange={getColorCode} />
          ) : null}
          <div>
            <Button
              variant="primary"
              className="me-1"
              onClick={() => setColorPicker(!colorPicker)}
            >
              {colorPicker ? "Close" : "Select BG Color"}
            </Button>
            <Button variant="primary" className="me-1" onClick={AddQr}>
              Save
            </Button>
          </div>{" "}
        </div>

        <div className="rounded-1 mb-5">
          <div className="rounded-2 shadow-lg p-2 position-relative">
            {/* <div/ className="image-overlay" /> */}
            <Background color={color} />
            <Row className="position-relative z-1">
              <Col md="12" className="text-center">
                {asEditable ? (
                  <div className="py-50" style={{}}>
                    <form className="d-flex" onSubmit={handleChangeText}>
                      <input
                        type="text"
                        onChange={(e) => setQuoteText(e.target.value)}
                        value={quoteText}
                        className="form-control w-100"
                        required
                      />
                      <button
                        type="submit"
                        className="float-left btn btn-primary"
                      >
                        Change
                      </button>

                      <input
                        onChange={(e) => setTextSize(e.target.value)}
                        type="text"
                        value={TextSize}
                        placeholder="Text Size px"
                        className="form-control w-100"
                        required
                      />
                      <button
                        type="submit"
                        className="float-left btn btn-primary"
                      >
                        Change
                      </button>
                    </form>
                  </div>
                ) : (
                  <h1
                    onClick={() => {
                      setAsEditable(!asEditable);
                    }}
                    className="title text-grey mt-5"
                    style={{ fontSize: TextSize }}
                  >
                    {quoteText}
                  </h1>
                )}
              </Col>
              <Col md="4" className="m-auto pt-4 text-center">
                <div className="ILM-one">
                  <ImageUploader
                    onChange={(e) => FileUpload(e, "profile_image_1")}
                    file={
                      values?.profile_image_1 &&
                      URL.createObjectURL(values?.profile_image_1)
                    }
                    aspect={1080 / 1080}
                  />
                </div>
                <div onClick={handleShow}>
                  <h3 className="loving-memory-name text-grey text-capitalize">
                    {values?.full_name}
                  </h3>
                  <h6 className="fw-4 text-grey">
                    {moment(values?.dob).format("MMMM Do, YYYY")} -
                    {moment(values?.death_date).format("MMMM Do, YYYY")}
                  </h6>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="rounded-1 mb-5">
          <>
          {
           Showinputseditstext ? <>
<div className="mt-3 text-end w-100 position-relative">
                  <input
                    type="text"
                    onChange={(e) => setShowEdit(e.target.value)}
                    className={"form-control w-50 border-2 position-absolute end-0 bottom-50 border-primary mt-2"}
                    style={{ borderStyle: "dashed" }}
                    required
                    value={showTextEdit}
                  />
                  <button
                    className="float-left mt-5  btn btn-primary"
                    onClick={() => setShowinputseditstext(!Showinputseditstext)}
                  >
                    Change
                  </button>
                </div>
                  </> : <>
                  <div className="flex text-end">
               
                  <Button
                    variant="primary"
                    className="me-1 my-4"
                    onClick={() => setShowinputseditstext(!Showinputseditstext)}
                  >
                    Edit Text size
                  </Button>
                </div>
                  </>
                }
          </>
          <div className="rounded-2 shadow-lg p-2 position-relative">
            {/* <div/ className="image-overlay" /> */}
            <Background color={color} />
            <Row className="position-relative z-1">
              <Col md="4" className="m-auto py-5">
                {" "}
                {/* <label for="dropzone-file2">
                  <img
                    src={
                      values?.profile_image_2
                        ? values?.profile_image_2
                        : "/images/button-01.png"
                    }
                    alt=""
                    className="w-100"
                    style={{ height: "71vh" }}
                  />
                  <input
                    onChange={handleChange}
                    id="dropzone-file2"
                    type="file"
                    name="profile_image_2"
                    className="d-none"
                  />
                </label> */}
                <div className="ILM-one">
                  <ImageUploader
                    onChange={(e) => FileUpload(e, "profile_image_2")}
                    file={
                      values?.profile_image_2 &&
                      URL.createObjectURL(values?.profile_image_2)
                    }
                    aspect={1080 / 1080}
                  />
                </div>
                <textarea
                  onChange={handleChange}
                  type="text"
                  autoFocus={true}
                  name="profile_text_2"
                  value={values?.profile_text_2}
                  className={"form-control border-2 border-primary mt-2"}
                  style={{ borderStyle: "dashed" ,fontSize:showTextEdit  }}
                />
              </Col>
            </Row>
          </div>
        </div>
        <Row className="row justify-content-end">
          <Col md="2" className="pb-4 text-right">
            <Button variant="primary" onClick={AddMore}>
              Add <FaPlus />
            </Button>
          </Col>
        </Row>

        {more.length > 0 && (
          <>
            {more.map((e, i) => (
              <div key={i} className="rounded-1 mb-5">
                <div className="rounded-2 shadow-lg p-2 position-relative">
                  <Background color={color} />
                  <Row className="position-relative z-1">
                    <Col md="6" className="m-auto">
                      <Row>
                        <Col md="12">
                          <div className="mb-2">
                            <div className="text-center">
                              <div className="ILM-one">
                                <ImageUploader
                                  onChange={(event) => handleChange2(event, i)}
                                  file={
                                    data[i]?.file &&
                                    URL.createObjectURL(data[i]?.file)
                                  }
                                  aspect={1080 / 1080}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-center">
                              <textarea
                                type="text"
                                onChange={(event) => handleChange2(event, i)}
                                value={data[i]?.value || ""}
                                className={
                                  "form-control border-1 border-primary w-75"
                                }
                                style={{ borderStyle: "dashed" ,fontSize:showTextEdit }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            ))}
          </>
        )}
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Suggestions to help you get started</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container ">
            <h5>Enter these details to personalize your cards</h5>
            <form>
              <div className="mb-3">
                <label className="form-label">Honoree's Name</label>
                <input
                  onChange={handleChange}
                  value={values?.full_name}
                  type="text"
                  className="form-control"
                  placeholder="Enter Honoree's Name"
                  required
                  name="full_name"
                />
              </div>
              {/* <div className="mb-3">
                <label className="form-label">Relation</label>
                <input
                  onChange={handleChange}
                  value={values?.relation}
                  type="text"
                  className="form-control"
                  placeholder="Enter Relation"
                  required
                  name="relation"
                />
              </div> */}
              <div className="mb-3">
                <label className="form-label">Date Of Birth</label>
                <input
                  onChange={handleChange}
                  value={values?.dob}
                  type="date"
                  className="form-control"
                  placeholder="Date of birth"
                  required
                  name="dob"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Date Of Passing</label>
                <input
                  onChange={handleChange}
                  value={values?.death_date}
                  type="date"
                  className="form-control"
                  placeholder="Date Of Passing"
                  required
                  name="death_date"
                />
              </div>
              {/*<div className="mb-3">
                <label className="form-label">Cover Photo</label>
                <input
                  onChange={handleChange}
                  id="dropzone-file"
                  type="file"
                  name="cover_image"
                  className="form-control"
                />
              </div> */}
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tribute;
