import React from 'react'

export default function Background({color}) {
  return (
    <div
    style={{
        position:"absolute",
        top:0,
        left:0,
        width:"100%",
        height:"100%",
        backgroundColor:color
        
    }}
    
    ></div>
  )
}
