import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { BASE_URL_IMAGE } from "../../utils/variables";
// import required modules
import { toast } from "react-toastify";
// import { Navigation } from "swiper/modules";
import { axiosInstance } from "../../services/AxiosInstance";
import Background from "../components/Background";

const SavedTribute = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { fontSize, fontWeight } = {
    fontSize: "5rem",
    fontWeight: "600",
  };
  const fontSizeh2={
    fontSize: "4rem",
    fontWeight: "600",
  }
  const getQr = () => {
    setLoader(true);
    axiosInstance
      .get(`user/tribute/view`)
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message);
          setData(response.data.data);
          console.log(response);
        } else {
          toast.error(response.data.message);
          console.log(response);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getQr();
  }, []);


  return (
    <>
      {loader && <div className="loader" />}
      <Container>
      <div className="rounded-1 mb-5 pt-4">
          <div className="rounded-2 shadow-lg p-2 position-relative">
            {/* <div/ className="image-overlay" /> */}
            <Background color={data?.color} />
            <Row className="position-relative z-1">
            <Col md="12" className="text-center pt-4">
            <h1
                  className="title text-white"
                  style={{ fontSize, fontWeight }}
                >
                 {data?.heading}
                </h1>
              </Col>
              <Col md="12" className="m-auto pt-4 text-center">
               
      
                <div className="ILM-one">
                <label for="dropzone-file">
                  <img
                    src={data?.profile_image_1}
                    alt=""
                  className="w-100"
                  style={{ height: "71vh" }}
                  />
                </label>
                </div>
                <div>
                <h1
                  className="title text-white"
                  style={{ fontSizeh2, fontWeight }}
                >
                    {data?.full_name}
                  </h1>
                  <h6 className="fw-4 text-grey cursor-pointer">
                    {moment(data?.dob).format("MMMM Do, YYYY")} -
                    {moment(data?.death_date).format("MMMM Do, YYYY")}
                  </h6>
                  <p className="text-grey">  {data?.relation}</p>
                </div>
              </Col>
        
            </Row>
          </div>
       
       
        </div>
      <div className="rounded-1 mb-5">
          <div className="rounded-2 shadow-lg p-2 position-relative">
            {/* <div/ className="image-overlay" /> */}
            <Background color={data?.color} />
            <Row className="position-relative z-1">
           
              <Col md="12" className="m-auto pt-4 text-center">
               
      
                <div className="ILM-one">
                <label for="dropzone-file">
                <img
                  src={data?.profile_image_2}
                  alt=""
                  className="w-100"
                  style={{ height: "71vh" }}
                />
                </label>
                </div>
                <div >
                 
                  <h1
                  className="title text-white"
                  style={{ fontSizeh2, fontWeight }}
                >
                 {data?.profile_text_2}
                </h1>
                </div>
              </Col>
         
            </Row>
          </div>
       
       
        </div>
     

        {data?.gallery?.map((e)=>(
            <div className="rounded-1 mb-5">
            <div className="rounded-2 shadow-lg p-2 position-relative">
              {/* <div/ className="image-overlay" /> */}
              <Background color={data?.color} />
              <Row className="position-relative z-1 py-4">
             
                <Col md="12" className="m-auto  text-center">
                 
        
                  <div className="ILM-one">
                  <label for="dropzone-file">
                  <img
                    src={BASE_URL_IMAGE+e?.gallery}
                    alt=""
                    className="w-100"
                    style={{ height: "71vh" }}
                  />
                  </label>
                  </div>
                  <div >
                   
                    <h1
                    className="title text-white"
                    style={{ fontSizeh2, fontWeight }}
                  >
                   {e.gallery_name}
                  </h1>
                  </div>
                </Col>
               
              </Row>
            </div>
         
         
          </div>
        ))}
      </Container>
   
    </>
  );
};

export default SavedTribute;
