import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import logo1 from "../../../images/logo1.png";
import logo2 from "../../../images/logo2.png";
const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { openMenuToggle } = useContext(ThemeContext);
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        {toggle ? (
          <Fragment>
            <img src={logo2} alt="" className="rounded w-100" />
          </Fragment>
        ) : (
          <Fragment>
            <img src={logo1} alt="" className="rounded w-50" />
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
