/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { Link } from "react-router-dom";
import { FaFloppyDisk } from "react-icons/fa6";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  const path = window.location.pathname;
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll mt-5">
        <MM className="metismenu" id="menu">
          <li className={`mb-2 ${path === "/" ? "mm-active" : ""}`}> 
           <Link className="" to="/">
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text">Tribute</span>
            </Link> 
         
           </li> 
          <li className={`${path === "/savedtribute" ? "mm-active" : ""}`}> 
           <Link className="" to="/savedtribute">
         
           <FaFloppyDisk  color={path === "/savedtribute" ? "#ffffff" : "#bfbfbf"} size={25}/>

              <span className="nav-text">Saved Tribute</span>
            </Link> 
         
           </li> 

          {/* <li
            className={`${
              path === "/" || path === "/dashboard" ? "mm-active" : ""
            }`}
          >
            <Link className="" to="/">
              <i className="fa fa-tv"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li className={`${path === "/broker" ? "mm-active" : ""}`}>
            <Link className="" to="/broker">
              <i className="fa fa-user"></i>
              <span className="nav-text">Broker</span>
            </Link>
          </li> */}
          {/* <li className={`${path === "/PDF" ? "mm-active" : ""}`}>
            <Link className="" to="/PDF">
              <i className="fa fa-file"></i>
              <span className="nav-text">PDF</span>
            </Link>
          </li> */}
          {/* <li className={`${path === "/driver" ? "mm-active" : ""}`}>
            <Link className="" to="/driver">
              <i className="fa fa-car"></i>
              <span className="nav-text">Driver</span>
            </Link>
          </li> */}
          {/* <li className={`${path === "/DriverPDF" ? "mm-active" : ""}`}>
            <Link className="" to="/DriverPDF">
              <i className="fa fa-user"></i>
              <span className="nav-text">DriverPDF</span>
            </Link>
          </li> */}
        </MM>
        <div className="copyright">
          <p>
            <strong>Our Tribute</strong> © 2024 All Rights Reserved
          </p>
          <p className="fs-12">
            Made with <span className="heart"></span> by HnHTechSolutions
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
